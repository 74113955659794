<template lang="pug">
TheBook(
	:title="transport.title"
	:endpoint="transport.endpoint"
	:path="transport.path"
	:left="transport.left"
	:leftSub="transport.leftSub"
)
</template>

<script>
import TheBook from '../components/TheBook'
import BOOKS from '../modules/constants/books'

export default {
	name: 'Transport',
	components: { TheBook },
	setup () {
		const { transport } = BOOKS

		return {
			transport
		}
	}
}
</script>
